import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import helperReducer from "./features/helperSlice";
import userReducer from "./features/userSlice";
import { userApi } from "./api/userApi";
import { taskApi } from "./api/taskApi";

export const store = configureStore({
  reducer: {
    helper: helperReducer,
    user: userReducer,
    [userApi.reducerPath]: userApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([userApi.middleware, taskApi.middleware]),
});
setupListeners(store.dispatch);
