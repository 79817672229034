import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "../redux/features/helperSlice";
import { AnimatePresence, motion } from "framer-motion";
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoInformationCircle,
  IoClose,
} from "react-icons/io5";
const Alert = () => {
  const { alert } = useSelector((state) => state.helper);
  return (
    <div className="absolute z-40 top-0 pt-3 max-w-sm px-4 flex items-center justify-center flex-col gap-3 mx-auto left-0 right-0">
      <AnimatePresence>
        {alert.length > 0 &&
          alert.map((item, index) => {
            return <AlertContainer key={item.id} item={item} />;
          })}
      </AnimatePresence>
    </div>
  );
};

export default Alert;

export const AlertContainer = ({ item }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let alertTime;
    if (item) {
      alertTime = setTimeout(() => {
        dispatch(closeAlert(item.id));
      }, 3000);
      return () => clearTimeout(alertTime);
    }
  }, [dispatch, item]);
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -30, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -10, scale: 0.5 }}
      id={item.id}
      className="bg-white/50 border border-slate-200 p-3 backdrop-filter backdrop-blur-sm max-w-sm rounded-md shadow-lg text-sm hover:shadow-none flex items-start justify-between gap-2"
    >
      <div>
        {item.status === "error" && (
          <IoCloseCircle className="text-lg text-red-600 grow" />
        )}
        {item.status === "info" && (
          <IoInformationCircle className="text-lg text-blue-500 " />
        )}
        {item.status === "warning" && (
          <IoInformationCircle className="text-lg text-yellow-500 " />
        )}
        {item.status === "success" && (
          <IoCheckmarkCircle className="text-lg text-green-600 " />
        )}
      </div>
      <div>{item.message}</div>
      <button onClick={() => dispatch(closeAlert(item.id))}>
        <IoClose />
      </button>
    </motion.div>
  );
};
