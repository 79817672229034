import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import {
  useGetAllUserQuery,
  useUserDeleteMutation,
} from "../../redux/api/userApi";
import { openAlert } from "../../redux/features/helperSlice";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const Users = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const {
    data: usersData,
    isLoading,
    isError: userIsError,
    error: userError,
  } = useGetAllUserQuery();

  const [
    userDelete,
    {
      data: deleteData,
      isLoading: deleteIsLoading,
      isSuccess: deleteIsSuccess,
      isError: deleteIsError,
      error: deleteError,
    },
  ] = useUserDeleteMutation();
  useEffect(() => {
    if (userIsError) {
      dispatch(
        openAlert({
          message: userError?.data?.message,
          status: "error",
        })
      );
    }
  }, [userIsError, userError?.data?.message, dispatch]);

  useEffect(() => {
    if (deleteIsError) {
      dispatch(
        openAlert({
          message: deleteError?.data?.message,
          status: "error",
        })
      );
    }
    if (deleteIsSuccess) {
      dispatch(
        openAlert({
          message: deleteData?.message,
          status: "success",
        })
      );
    }
  }, [
    deleteIsError,
    deleteIsSuccess,
    deleteError?.data?.message,
    deleteData?.message,
    dispatch,
  ]);

  if (isLoading || deleteIsLoading) {
    return <Loading />;
  }
  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <h3 className="font-semibold text-slate-800 text-lg">All Users</h3>
        {currentUser.roles.includes("admin") && (
          <Link to="create" className="btn">
            Create
          </Link>
        )}
      </div>
      {usersData?.result && usersData?.result.length > 0 ? (
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className="flex flex-col w-full gap-3"
        >
          <AnimatePresence>
            {usersData?.result.map((user, index) => {
              return (
                <motion.div
                  variants={child}
                  key={user._id}
                  className="backdrop:blur-sm bg-white/50 backdrop-blur p-3 border border-slate-200 rounded flex flex-col w-full gap-1"
                >
                  <h4 className="font-medium text-slate-600 leading-5">{`${user.firstName} ${user.lastName}`}</h4>
                  <div className="w-full flex gap-2 justify-between items-start">
                    <div className="text-xs text-gray-500 font-light">
                      {user.email}
                    </div>
                    <div className="text-xs text-gray-500 font-light">
                      {user.roles.join(" | ").toUpperCase()}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-sm flex gap-1">
                      Tasks:
                      <span className="bg-blue-100 w-5 h-5 grid place-content-center rounded">
                        {user.tasks.length}
                      </span>
                    </div>
                    {currentUser.roles.includes("admin") && (
                      <button
                        className="btn btn-sm !bg-red-500"
                        onClick={() => userDelete(user._id)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </motion.div>
      ) : (
        <div className="backdrop:blur-sm bg-white/50 backdrop-blur p-3 border border-slate-200 rounded">
          No user found !
        </div>
      )}
    </>
  );
};

export default Users;
