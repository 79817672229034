import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserLoginMutation } from "../../redux/api/userApi";
import { setUser } from "../../redux/features/userSlice";
import { openAlert } from "../../redux/features/helperSlice";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [userLogin, { data, isLoading, isError, error, isSuccess }] =
    useUserLoginMutation();
  useEffect(() => {
    if (currentUser) {
      navigate(from, { replace: true });
    }
  }, [currentUser, from, navigate]);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    /* eslint-disable */
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      dispatch(
        openAlert({
          message: "Please enter valid email address",
          status: "error",
        })
      );
      return false;
    }
    userLogin(userData);
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser(data));
      dispatch(
        openAlert({
          message: data?.message,
          status: "success",
        })
      );
    }
    if (isError) {
      dispatch(
        openAlert({
          message: error?.data?.message,
          status: "error",
        })
      );
    }
  }, [isSuccess, isError]);
  const canSave = [userData.email.trim(), userData.password.trim()].every(
    Boolean
  );
  return (
    <div className="p-6 bg-white/50 backdrop-blur border border-slate-200  rounded-md">
      <h4 className="text-slate-800 font-bold text-2xl leading-5 mb-4">
        <span className="text-base ">Hello,</span>
        <br />
        Welcome back again!
      </h4>

      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />
        </div>
        <button type="submit" disabled={!canSave || isLoading} className="btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Login;
