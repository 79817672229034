import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import {
  useDeleteTaskMutation,
  useGetAllTasksQuery,
} from "../../redux/api/taskApi";
import { openAlert } from "../../redux/features/helperSlice";
import { AnimatePresence, motion } from "framer-motion";
import TaskItem from "../../components/TaskItem";
import { Link } from "react-router-dom";
import { SocketContext } from "../../context/socket";

const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const Task = () => {
  const socket = useContext(SocketContext);

  const dispatch = useDispatch();
  const [completedTask, setCompletedTask] = useState(0);
  const {
    data: taskData,
    isError: taskIsError,
    isLoading: taskIsLoading,
    error: taskError,
    refetch,
  } = useGetAllTasksQuery();

  const [
    deleteTask,
    {
      data: deletedTaskData,
      error: deleteError,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
      isLoading: deleteIsLoading,
    },
  ] = useDeleteTaskMutation();

  useEffect(() => {
    if (deleteIsError) {
      dispatch(
        openAlert({
          message: deleteError?.data?.message,
          status: "error",
        })
      );
    }
    if (deleteIsSuccess) {
      dispatch(
        openAlert({
          message: deletedTaskData?.message,
          status: "success",
        })
      );
      socket.emit("deleteTask", "taskDeleted");
    }
  }, [
    taskIsError,
    deleteIsError,
    deleteIsSuccess,
    deleteError?.data?.message,
    deletedTaskData?.message,
    socket,
    dispatch,
  ]);

  useEffect(() => {
    if (taskIsError) {
      dispatch(
        openAlert({
          message: taskError?.data?.message,
          status: "error",
        })
      );
    }
  }, [taskIsError, taskError?.data?.message, dispatch]);

  useEffect(() => {
    if (taskData?.result) {
      const taskCount = taskData?.result.filter(
        (item) => item.completed === true
      );
      setCompletedTask(taskCount?.length);
    }
  }, [taskData?.result]);

  const actionDelete = (id) => {
    deleteTask(id);
  };

  const refreshList = () => {
    refetch();
  };

  // useEffect(() => {
  //   socket.on("fetchTask", (data) => {
  //     refetch();
  //   });
  //   return () => socket.off();
  // }, [socket, refetch]);

  if (taskIsLoading || deleteIsLoading) {
    return <Loading />;
  } else
    return (
      <>
        <div className="flex items-center justify-between mb-3">
          <h3 className="font-semibold text-slate-800 text-lg">
            All Tasks :{" "}
            <span className="text-sm">
              {completedTask} / {taskData?.result.length}
            </span>
          </h3>
          <div className="flex gap-4">
            <button className="text-xs underline" onClick={refreshList}>
              Refresh
            </button>
            <Link to="create" className="btn">
              Create
            </Link>
          </div>
        </div>
        <motion.div
          className="w-full flex flex-col gap-3"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <AnimatePresence>
            {taskData?.result && taskData?.result.length > 0 ? (
              taskData?.result.map((item, index) => {
                return (
                  <TaskItem
                    key={item._id}
                    item={item}
                    disableUpdate="true"
                    actionDelete={actionDelete}
                  />
                );
              })
            ) : (
              <motion.div
                variants={child}
                className="backdrop:blur-sm bg-white/50 backdrop-blur p-3 border border-slate-200 rounded"
              >
                No task available
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </>
    );
};

export default Task;
