import React from "react";
import { CgSpinner } from "react-icons/cg";
const Loading = () => {
  return (
    <div className="bg-white/10 fixed top-0 left-0 w-full h-full z-50 grid place-content-center backdrop-filter backdrop-blur-sm">
      <CgSpinner className="w-8 h-8 animate-spin text-blue-500" />
    </div>
  );
};

export default Loading;
