import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetMyTasksQuery,
  useUpdatedTaskMutation,
} from "../redux/api/taskApi";
import { openAlert } from "../redux/features/helperSlice";
import NOWORK from "./../assets/nowork.gif";
import TaskItem from "../components/TaskItem";
import { SocketContext } from "../context/socket";

const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const Home = () => {
  const socket = useContext(SocketContext);
  const [selectedTab, setSelectedTab] = useState("All");

  const dispatch = useDispatch();

  const {
    data: taskData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetMyTasksQuery();
  const [
    updatedTask,
    {
      data: updatedData,
      isError: updatedIsError,
      isSuccess: updatedIsSuccess,
      error: updatedError,
      isLoading: updatedIsLoading,
    },
  ] = useUpdatedTaskMutation();

  const [allList, setAllList] = useState(null);

  const filteredTodoList = allList?.filter((item) => {
    return selectedTab === "Completed"
      ? item.completed === true
      : selectedTab === "Pending"
      ? item.completed === false
      : item;
  });
  useEffect(() => {
    if (isError) {
      dispatch(
        openAlert({
          message: error?.data?.message,
          status: "error",
        })
      );
    }
  }, [isError, error?.data?.message, dispatch]);
  useEffect(() => {
    if (taskData) {
      setAllList(taskData.result);
    }
  }, [taskData]);

  useEffect(() => {
    if (updatedIsSuccess) {
      dispatch(
        openAlert({
          message: updatedData?.message,
          status: "success",
        })
      );
      socket.emit("updateTask", "taskUpdated");
    }
    if (updatedIsError) {
      dispatch(
        openAlert({
          message: updatedError?.data?.message,
          status: "error",
        })
      );
    }
  }, [
    updatedIsSuccess,
    updatedIsError,
    dispatch,
    updatedData?.message,
    updatedError?.data?.message,
    socket,
  ]);

  const actionUpdate = (id) => {
    updatedTask(id);
  };

  const refreshList = () => {
    refetch();
  };

  return (
    <>
      <div className="flex items-end justify-between mb-3">
        <h3 className="font-semibold text-slate-800 text-lg">My Tasks</h3>
        <button className="text-xs underline" onClick={refreshList}>
          Refresh
        </button>
      </div>
      {isLoading || updatedIsLoading ? (
        <Loading />
      ) : allList && allList.length > 0 ? (
        <>
          <div className="backdrop:blur-sm bg-white/50 backdrop-blur p-3 border border-slate-200 rounded-3xl flex items-center justify-center gap-3 mb-3">
            {["All", "Pending", "Completed"].map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    item === selectedTab ? "selected" : "relative"
                  } px-5 py-1 shadow-inner rounded-3xl`}
                  onClick={() => setSelectedTab(item)}
                >
                  <span
                    className={`${
                      item === selectedTab ? " text-white" : "text-slate-800"
                    } relative z-10 text-sm`}
                  >
                    {item}
                  </span>
                  {item === selectedTab ? (
                    <motion.div
                      className="absolute bg-gradient-to-t from-blue-600 to-cyan-500 top-0 left-0 w-full h-full rounded-3xl shadow"
                      layoutId="underline"
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
          <motion.div
            className="w-full flex flex-col gap-3"
            variants={container}
            initial="hidden"
            animate="visible"
          >
            <AnimatePresence>
              {filteredTodoList.length > 0 ? (
                filteredTodoList.map((item) => {
                  return (
                    <TaskItem
                      key={item._id}
                      item={item}
                      actionUpdate={actionUpdate}
                    />
                  );
                })
              ) : (
                <motion.div
                  variants={child}
                  className="backdrop:blur-sm bg-white/50 backdrop-blur p-3 border border-slate-200 rounded"
                >
                  No task available
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </>
      ) : (
        <>
          <div className="p-3 bg-gradient-to-r from-blue-600 to-cyan-500 rounded-md shadow-xl mb-4 text-white text-sm">
            It's look like you don't have any task in the list, Enjoy your day
            and buy a coffee for the developer.
          </div>
          <div className="p-3 shadow-sm rounded-md text-sm flex flex-col gap-4">
            <img src={NOWORK} alt="no work" className="w-full" />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
