import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const serverURL = "http://localhost:8000/api/v1";
const serverURL = process.env.REACT_APP_API_URL;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/user/`,
    prepareHeaders: (headers) => {
      const authToken =
        localStorage.getItem("currentUser") &&
        JSON.parse(localStorage.getItem("currentUser"));
      if (authToken) headers.set("authorization", `Bearer ${authToken.token}`);
      return headers;
    },
  }),
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    userRegister: builder.mutation({
      query: (user) => ({
        url: `/register`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    userDelete: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    userLogin: builder.mutation({
      query: (user) => ({
        url: `/login`,
        method: "POST",
        body: user,
      }),
    }),
    getAllUser: builder.query({
      query: () => ({
        url: `/getAllUser`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
  }),
});

export const {
  useUserRegisterMutation,
  useUserLoginMutation,
  useUserDeleteMutation,
  useGetAllUserQuery,
} = userApi;
