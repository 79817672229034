import React from "react";
import { Outlet } from "react-router-dom";
import Alert from "./Alert";
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  return (
    <>
      <Alert />
      <main className="relative z-10 min-h-screen flex flex-col justify-between">
        <Header />
        <section className="py-4 mb-auto">
          <div className="max-w-sm mx-auto px-4">
            <Outlet />
          </div>
        </section>
        <Footer />
      </main>
      <div className="gradient"></div>
    </>
  );
};

export default Layout;
