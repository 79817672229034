import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/auth/Login";
import PageNotFound from "./pages/auth/PageNotFound";
import PageUnauthorized from "./pages/auth/PageUnauthorized";
import Profile from "./pages/auth/Profile";
import Home from "./pages/Home";
import Task from "./pages/task";
import CreateTask from "./pages/task/CreateTask";
import Users from "./pages/users";
import CreateUser from "./pages/users/CreateUser";
import { socket } from "./context/socket";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllTasksQuery, useGetMyTasksQuery } from "./redux/api/taskApi";

const App = () => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { refetch: allRefetch } = useGetAllTasksQuery();
  const { refetch: myRefetch } = useGetMyTasksQuery();
  useEffect(() => {
    if (currentUser?.email) {
      socket.on("fetchTask", (data) => {
        allRefetch();
        myRefetch();
      });
    }
    return () => socket.off();
  }, [dispatch, allRefetch, myRefetch, currentUser?.email]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route
          element={
            <ProtectedRoute allowedRoles={["user", "taskmanager", "admin"]} />
          }
        >
          <Route index element={<Home />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route
          path="task"
          element={<ProtectedRoute allowedRoles={["taskmanager", "admin"]} />}
        >
          <Route index element={<Task />} />
          <Route path="create" element={<CreateTask />} />
        </Route>
        <Route
          path="user"
          element={<ProtectedRoute allowedRoles={["taskmanager", "admin"]} />}
        >
          <Route index element={<Users />} />
          <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
            <Route path="create" element={<CreateUser />} />
          </Route>
        </Route>
        <Route path="unauthorized" element={<PageUnauthorized />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
