import React from "react";
import { Link } from "react-router-dom";
import pageNotFound from "./../../assets/pageNotFound.gif";

const PageNotFound = () => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="p-3 bg-gradient-to-r from-blue-600 to-cyan-500 rounded-md shadow-xl text-white flex flex-col gap-3 items-center">
        <p className="text-xl font-bold">404 - Page not found.</p>
        <p className="text-sm text-center">
          We're sorry, the page you requested could not be found. Please go back
          to the homepage.
        </p>
        <Link to="/" className="btn !bg-transparent border border-white">
          Go to Home page
        </Link>
      </div>
      <div className="p-3 shadow-sm rounded-md text-sm flex flex-col gap-4">
        <img src={pageNotFound} alt="no work" className="w-full" />
      </div>
    </div>
  );
};

export default PageNotFound;
