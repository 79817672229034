import React from "react";
import moment from "moment";
import { motion } from "framer-motion";
import { FiCheck } from "react-icons/fi";

const TaskItem = ({ item, actionUpdate, disableUpdate, actionDelete }) => {
  const child = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="bg-white/50 backdrop-blur backdrop:blur-sm p-3 rounded flex w-full items-start justify-start gap-2 border border-slate-200 relative"
      variants={child}
    >
      {item.urgent === true && (
        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span
            className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
              item.completed === true ? "bg-green-400" : "bg-red-400"
            }`}
          ></span>
          <span
            className={`relative inline-flex rounded-full h-3 w-3  ${
              item.completed === true ? "bg-green-500" : "bg-red-500"
            }`}
          ></span>
        </span>
      )}

      {!disableUpdate && (
        <div
          className={`w-5 h-5 rounded grid place-content-center shrink-0 ${
            item.completed === true ? "bg-blue-500" : "bg-gray-300"
          }`}
          onClick={() => actionUpdate(item._id)}
        >
          {item.completed && <FiCheck className="text-white" />}
        </div>
      )}

      <div className="grow flex flex-col gap-1 w-full ">
        <h4
          className={`font-medium text-slate-600 leading-5 ${
            item.completed === true && "line-through"
          }`}
        >
          {item.title}
        </h4>
        <div className="w-full flex gap-2 justify-between items-start">
          <div className="text-xs text-gray-500 font-light">
            {moment(item._createdAt).fromNow()}
          </div>
          <div className="text-xs text-gray-500 font-light">
            {`By ${item.assignedBy.firstName} ${item.assignedBy.lastName}`}
          </div>
        </div>
        {disableUpdate && (
          <div className="flex justify-between">
            <div className="text-xs text-gray-500 font-light">
              To {item.asignTo.firstName} {item.asignTo.lastName}
            </div>
            <button
              className="btn btn-sm !bg-red-500"
              onClick={() => actionDelete(item._id)}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default TaskItem;
