import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { SocketContext } from "../../context/socket";
import { useCreateTaskMutation } from "../../redux/api/taskApi";
import { useGetAllUserQuery } from "../../redux/api/userApi";
import { openAlert } from "../../redux/features/helperSlice";

const CreateTask = () => {
  const socket = useContext(SocketContext);
  const formRef = useRef();
  const dispatch = useDispatch();
  const {
    data: userList,
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
  } = useGetAllUserQuery();
  const [
    createTask,
    {
      data: createdTask,
      isLoading: createIsLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
      error: createError,
    },
  ] = useCreateTaskMutation();
  useEffect(() => {
    if (userIsError) {
      dispatch(
        openAlert({
          message: userError?.data?.message,
          status: "error",
        })
      );
    }
  }, [userIsError, userError?.data?.message, dispatch]);

  const [taskData, setTaskData] = useState({
    title: "",
    asignTo: "",
    urgent: "no",
  });
  const canSave = [taskData.title.trim(), taskData.asignTo.trim()].every(
    Boolean
  );

  useEffect(() => {
    if (createIsSuccess) {
      dispatch(
        openAlert({
          open: true,
          message: createdTask?.message,
          status: "success",
        })
      );
      formRef.current.reset();
      setTaskData({
        title: "",
        asignTo: "",
        urgent: "no",
      });

      socket.emit("createTask", "taskCreated");
    }
    if (createIsError) {
      dispatch(
        openAlert({
          open: true,
          message: createError?.data?.message,
          status: "error",
        })
      );
    }
  }, [
    createIsSuccess,
    createIsError,
    dispatch,
    createdTask?.message,
    createError?.data?.message,
    socket,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canSave) {
      createTask({
        ...taskData,
        urgent: taskData.urgent === "no" ? false : true,
      });
    }
  };

  if (userIsLoading || createIsLoading) {
    return <Loading />;
  } else
    return (
      <>
        <div className="flex items-center justify-between mb-3">
          <h3 className="font-semibold text-slate-800 text-lg">
            Create a new tasks
          </h3>
        </div>
        <div className="p-4 w-full bg-white/50 backdrop-blur border border-slate-100 shadow-sm rounded-md">
          <form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={taskData.title}
                onChange={(e) =>
                  setTaskData({ ...taskData, title: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="asignTo" className="form-label">
                Select User
              </label>
              <select
                className="form-control"
                id="asignTo"
                name="asignTo"
                value={taskData.asignTo}
                onChange={(e) =>
                  setTaskData({ ...taskData, asignTo: e.target.value })
                }
              >
                <option>Select user</option>
                {userList?.result?.map((user) => {
                  return (
                    <option
                      key={user._id}
                      value={user._id}
                    >{`${user.firstName} ${user.lastName}`}</option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="urgent" className="form-label">
                Urgent
              </label>
              <select
                className="form-control"
                id="urgent"
                name="urgent"
                value={taskData.urgent}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    urgent: e.target.value,
                  })
                }
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <button
              type="submit"
              disabled={!canSave || createIsLoading || userIsLoading}
              className="btn"
            >
              Submit
            </button>
          </form>
        </div>
      </>
    );
};

export default CreateTask;
