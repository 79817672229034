import React from "react";
import { AiOutlineLogout } from "react-icons/ai";
// import { GiJusticeStar } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { userApi } from "../redux/api/userApi";
import { taskApi } from "../redux/api/taskApi";
import { Link } from "react-router-dom";
import { logoutUser } from "../redux/features/userSlice";
import SOLLOGO from "./../assets/sollogo.png";
const Header = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const logout = () => {
    dispatch(userApi.util.resetApiState());
    dispatch(taskApi.util.resetApiState());
    dispatch(logoutUser());
  };
  return (
    <header className="bg-white/5 backdrop-blur sticky top-0 left-0 w-full border-b border-b-slate-200 shadow-sm py-2 z-50">
      <div className="container max-w-sm mx-auto px-4 flex items-center justify-between">
        <Link to="/">
          {/* <GiJusticeStar className="text-blue-600 text-3xl" /> */}
          <img src={SOLLOGO} alt="Sol" className="w-9" />
        </Link>
        <nav className="flex gap-4 items-center justify-center text-slate-900">
          {currentUser ? (
            <>
              {currentUser.roles.includes("taskmanager") && (
                <>
                  <Link to="task">All Task</Link>
                </>
              )}
              {(currentUser.roles.includes("admin") ||
                currentUser.roles.includes("taskmanager")) && (
                <>
                  <Link to="user">Users</Link>
                </>
              )}
              <Link
                to="profile"
                className="rounded-full bg-gradient-to-t from-blue-600 to-cyan-500 text-sm w-7 h-7 grid place-content-center text-slate-50"
              >
                {currentUser.firstName.charAt(0).toUpperCase()}
              </Link>
              <button onClick={logout}>
                <AiOutlineLogout className="text-lg" />
              </button>
            </>
          ) : (
            <Link to="login">Login</Link>
          )}
          {/* <Link to="/">Tasks</Link>
          <Link to="/">users</Link> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
