import React, { useState } from "react";
import { useSelector } from "react-redux";

const Profile = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [userData, setUserData] = useState({
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    email: currentUser?.email || "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const changePasswordHandle = (e) => {
    e.preventDefault();
  };
  return (
    <div className="w-full">
      <div className="mb-4">
        <div className="p-3 bg-blue-500/20 text-sm rounded border border-blue-900/5 mb-3">
          <strong>Update profile</strong> and <strong>change password</strong>{" "}
          process is in progress, please apply the changes later.
        </div>
        <h3 className="font-semibold text-slate-800 text-lg leading-3 mb-3">
          <span className="text-base ">Hello,</span>
          <br />
          {`${currentUser.firstName} ${currentUser.lastName}`}
        </h3>
        <div className="p-4 w-full bg-white/50 backdrop-blur border border-slate-100 shadow-sm rounded-md">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={userData.firstName}
                onChange={(e) =>
                  setUserData({ ...userData, firstName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={userData.lastName}
                onChange={(e) =>
                  setUserData({ ...userData, lastName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                readOnly
                type="email"
                className="form-control"
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </div>

            <button type="submit" disabled className="btn">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div>
        <h3 className="font-semibold text-slate-800 text-lg mb-3">
          Change password
        </h3>
        <div className="p-4 w-full bg-white/50 backdrop-blur border border-slate-100 shadow-sm rounded-md">
          <form onSubmit={changePasswordHandle} autoComplete="off">
            <div className="form-group">
              <label htmlFor="oldPassword" className="form-label">
                Old password
              </label>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword" className="form-label">
                New password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                readOnly
              />
            </div>
            <button type="submit" disabled className="btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
