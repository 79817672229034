import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const serverURL = "http://localhost:8000/api/v1";
const serverURL = process.env.REACT_APP_API_URL;
// const SOCKETURL = "http://localhost:8000"

export const taskApi = createApi({
  reducerPath: "taskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/task/`,
    prepareHeaders: (headers) => {
      const authToken =
        localStorage.getItem("currentUser") &&
        JSON.parse(localStorage.getItem("currentUser"));
      if (authToken) headers.set("authorization", `Bearer ${authToken.token}`);
      return headers;
    },
  }),
  tagTypes: ["Tasks"],
  endpoints: (builder) => ({
    getAllTasks: builder.query({
      query: () => ({
        url: `/getAllTask`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    getMyTasks: builder.query({
      query: () => ({
        url: `/getMyTasks`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    createTask: builder.mutation({
      query: (body) => ({
        url: `/create`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Tasks"],
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `/deleteTask/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),
    updatedTask: builder.mutation({
      query: (id) => ({
        url: `/updatedTask/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Tasks"],
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useGetMyTasksQuery,
  useDeleteTaskMutation,
  useUpdatedTaskMutation,
} = taskApi;
