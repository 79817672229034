import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
export const helperSlice = createSlice({
  name: "helper",
  initialState: {
    alert: [],
  },
  reducers: {
    openAlert: (state, action) => {
      state.alert = [{ id: uuidv4(), ...action.payload }, ...state.alert];
    },
    closeAlert: (state, action) => {
      state.alert = state.alert.filter((e) => e.id !== action.payload);
    },
  },
});

export const { openAlert, closeAlert } = helperSlice.actions;

export default helperSlice.reducer;
