import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { useUserRegisterMutation } from "../../redux/api/userApi";
import { openAlert } from "../../redux/features/helperSlice";

const CreateUser = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [userRegister, { data, isLoading, isError, error, isSuccess }] =
    useUserRegisterMutation();

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "123456",
    roles: ["user"],
  });

  function checkValue(e) {
    const val = e.target.value;
    if (userData.roles.length === 0) {
      setUserData({ ...userData, roles: [val] });
    } else if (userData.roles.length > 0) {
      if (userData.roles.includes(val)) {
        const newRole = userData.roles.filter((role) => role !== val);
        setUserData({ ...userData, roles: newRole });
      } else {
        setUserData({ ...userData, roles: [...userData.roles, val] });
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    /* eslint-disable */
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      dispatch(
        openAlert({
          message: "Please enter valid email address",
          status: "error",
        })
      );
      return false;
    }
    userRegister(userData);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        openAlert({
          open: true,
          message: data?.message,
          status: "success",
        })
      );
      formRef.current.reset();
      setUserData({
        firstName: "",
        lastName: "",
        email: "",
        password: "123456",
        roles: ["user"],
      });
    }
    if (isError) {
      dispatch(
        openAlert({
          message: error?.data?.message,
          status: "error",
        })
      );
    }
  }, [isSuccess, isError]);

  const canSave = [
    userData.firstName.trim(),
    userData.lastName.trim(),
    userData.email.trim(),
    userData.password.trim(),
  ].every(Boolean);

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        <div className="flex items-center justify-between mb-3">
          <h3 className="font-semibold text-slate-800 text-lg">
            Create a new user
          </h3>
        </div>
        <div className="p-4 w-full bg-white/50 backdrop-blur border border-slate-100 shadow-sm rounded-md">
          <form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
            <div className="form-group">
              <label htmlFor="firstName" className="form-label">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={userData.firstName}
                onChange={(e) =>
                  setUserData({ ...userData, firstName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="form-label">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={userData.lastName}
                onChange={(e) =>
                  setUserData({ ...userData, lastName: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="text"
                className="form-control"
                id="password"
                value={userData.password}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label className="form-label">Select role</label>
              <div className="flex gap-4">
                <label htmlFor="user" className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked
                    disabled
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50 mt-1"
                    name="roles"
                    value="user"
                    id="user"
                    onChange={checkValue}
                  />
                  User
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50 mt-1"
                    name="roles"
                    value="taskmanager"
                    id="taskmanager"
                    onChange={checkValue}
                  />
                  Task Manager
                </label>
              </div>
            </div>

            <button
              type="submit"
              disabled={!canSave || isLoading}
              className="btn"
            >
              Submit
            </button>
          </form>
        </div>
      </>
    );
};

export default CreateUser;
