import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white/5 backdrop-blur sticky top-0 left-0 w-full border-t border-t-slate-200 shadow-sm py-3 z-50">
      <div className="container max-w-sm mx-auto px-4  text-center text-xs">
        &copy; TaskSol. All rights reserved {new Date().getFullYear()}
      </div>
    </footer>
  );
};

export default Footer;
