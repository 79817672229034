import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "currentUser",
  initialState: {
    currentUser: JSON.parse(localStorage.getItem("currentUser"))?.user || null,
    token: JSON.parse(localStorage.getItem("currentUser"))?.token || null,
  },
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload.result.user;
      state.token = action.payload.result.token;
      localStorage.setItem(
        "currentUser",
        JSON.stringify(action.payload.result)
      );
    },
    logoutUser: (state, action) => {
      localStorage.removeItem("currentUser");
      state.currentUser = null;
      state.token = null;
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
